import { render, staticRenderFns } from "./Ic4gPcrfView.vue?vue&type=template&id=2a98dd33&scoped=true"
import script from "./Ic4gPcrfView.vue?vue&type=script&setup=true&lang=js"
export * from "./Ic4gPcrfView.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a98dd33",
  null
  
)

export default component.exports